<template>
  <div class="menus">
    <div id="">
      <el-form
        ref="queryForm"
        :inline="true"
        v-hasPermi="['learn:column:list']"
        label-width="60px"
        style="width: 100%; text-align: left"
      >
        <el-form-item label="标题" prop="roleName">
          <el-input
            v-model="queryParams.keywords"
            placeholder="请输入标题"
            clearable
            size="small"
            style="width: 200px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>

        <el-form-item label="类别" prop="roleKey">
          <el-select
            size="small"
            v-model="queryParams.pcate"
            @change="fnEdit"
            placeholder="请选择一级分类"
            style="width: 100%"
          >
            <el-option
              v-for="item in yiji"
              :key="item.id"
              :value="item.id"
              :label="item.title"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="" prop="status">
          <el-select
            size="small"
            v-model="queryParams.cate"
            placeholder="请选择二级分类"
            style="width: 100%"
          >
            <el-option
              v-for="its in erji"
              :key="its.id"
              :value="its.id"
              :label="its.title"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="" prop="status">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            v-hasPermi="['learn:column:list']"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetQuery"
            v-hasPermi="['learn:column:list']"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="tian">
      <el-button
        type="primary"
        plain
        icon="el-icon-plus"
        size="mini"
        @click="addition()"
        style="float: left"
        v-hasPermi="['learn:column:add']"
        >添加
      </el-button>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#F8F8F9', color: '#515A6E' }"
    >
      <el-table-column prop="title" label="标题" :show-overflow-tooltip="true">
      </el-table-column>
      <!-- <el-table-column prop="tag"  label="分类"> </el-table-column> -->
      <el-table-column prop="ccate" label="类别"> </el-table-column>

      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <!-- <span  >{{scope.row.status}}</span> -->
          <el-tag type="primary" plain v-if="scope.row.status === '1'"
            >显示</el-tag
          >
          <el-tag type="danger" plain v-else>隐藏</el-tag>
          <!--              <span v-show="scope.row.status===0" style="color:#F92672" >锁定</span>-->
          <!--              <span v-show="scope.row.status===1" style="color:#79D62E" >正常</span>-->
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
            v-hasPermi="['learn:column:eqit']"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
            v-hasPermi="['learn:column:delete']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- <el-pagination center background layout="prev, pager, next, sizes, total, jumper" :page-sizes="pageSizes"
        :page-size="pagesize" :page-count="pageCount" :total="total" :current-page.sync="pageNo" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" ref="pagination" @next-click="pages" @prev-click="pages">
      </el-pagination> -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="info()"
    />
  </div>
</template>



<script>
// import Pagination from "@/components/Pagination";
import request from "../../../utils/request.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  data() {
    return {
      total: 0,
      formLabelWidth: "120px",
      tableData: [],
      id: 0,
      queryParams: {
        keywords: "", //搜索名称
        pcate: "", //搜索电话
        cate: "", //搜索状态
        pageSize: 10, //分页默认一页显示15条数据
        total: 0, //共多少条数据从接口获取
        pageNum: 1, //分页默认显示第1页数据
      },
      yiji: [],
      erji: [],

      clickMsg: "1", //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
    };
  },
  created() {
    this.info();
    // this.getList();
  },

  activated() {
    this.info();
  },
  methods: {
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children,
      };
    },
    //获取列表数据
    info() {
      this.clickMsg = "1"; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页
      this.leibie();
      let dats = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        keywords: this.queryParams.keywords,
        pcate: this.queryParams.pcate,
        cate: this.queryParams.cate,
      };
      return request({
        url: "article/queryArticle",
        method: "get",
        params: dats,
      }).then((res) => {
        if (res.status === 200) {
          this.tableData = res.data.data;
          // this.queryParams.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
          this.total = res.data.pageBean.count; //接口获取到的共多少条
        }
      });
    },
    //  获取类别下拉
    leibie() {
      return request({
        url: "articleCategory/" + this.id,
        method: "get",
      }).then((res) => {
        if (res.status === 200) {
          this.yiji = res.data;
        }
      });
    },
    fnEdit(val) {
      return request({
        url: "articleCategory/" + val,
        method: "get",
      }).then((res) => {
        if (res.status === 200) {
          this.erji = res.data;
        }
      });
    },
    //添加
    addition() {
      this.$router.push({ path: "/Home/newly" });
    },

    //搜索
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.info();
    },
    //搜索重置
    resetQuery() {
      this.queryParams.keywords = ""; //搜索名称
      this.queryParams.pcate = ""; //搜索电话
      this.queryParams.cate = ""; //搜索状态
      this.info();
    },
    //编辑
    handleEdit(val, row) {
      this.$router.push({ path: "/Home/newly", query: { id: row.id } });
    },

    //删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            articleId: row.id,
          };
          return request({
            url: "article/delete",
            method: "delete",
            data: data,
          }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.info();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  height: auto;
}
.el-pagination {
  text-align: right;
}
</style>
 
